import React from 'react';
import './Loading.css';
import loadingGif from '../assets/loading.gif';

const Loading: React.FC = () => {
    return (
        <div className="loading-container">
            <img src={loadingGif} alt="Loading..." className="loading-gif" />
        </div>
    );
};

export default Loading;
