import React from "react";
import "./AdsLarge.css";

const AdsLarge: React.FC = () => {

    return (
            <div className="ads-large">
                {"Ads"}
            </div>
    );
};

export default AdsLarge;