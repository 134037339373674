import React, {useState, useRef} from 'react';
import 'react-quill/dist/quill.snow.css';
import ReCAPTCHA from 'react-google-recaptcha';
import './CommentInput.css';
import ReactQuill from "react-quill";

interface CommentInputProps {
    onAddComment: (text: string, author: string) => void;
}

const CommentInput: React.FC<CommentInputProps> = ({onAddComment}) => {
    const [newComment, setNewComment] = useState<string>('');
    const [author, setAuthor] = useState<string>('');
    const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const handleRecaptchaChange = (value: string | null) => {
        setRecaptchaValue(value);
    };

    const handleAddComment = async () => {
        if (newComment.trim() === '' || !recaptchaValue) {
            alert('Por favor completa el comentario y el CAPTCHA.');
            return;
        }

        setLoading(true);
        onAddComment(newComment, author.trim() === '' ? 'Anónimo' : author);
        setLoading(false);

        setNewComment('');
        setAuthor('');
        setRecaptchaValue(null);
        recaptchaRef.current?.reset();
    };

    const modules = {
        toolbar: [
            [{'header': [1, 2, false]}],
            ['bold', 'italic', 'underline', 'strike'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            ['code-block'],
            ['link', 'image'],
            [{'align': []}],
            ['clean']
        ],
    };

    const formats = [
        'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'link', 'image', 'code-block', 'align'
    ];

    return (
        <div className="input-container">
            <input
                type="text"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                placeholder="Nombre (dejar vacío para ser anónimo)"
                className="input-author-field"
            />
            <ReactQuill
                value={newComment}
                onChange={setNewComment}
                modules={modules}
                formats={formats}
                className="input-field"
                placeholder="Escribe un comentario con formato..."
            />
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ''}
                onChange={handleRecaptchaChange}
            />
            <button
                onClick={handleAddComment}
                className="add-button"
                disabled={loading || !recaptchaValue}
            >
                {loading ? 'Enviando...' : 'Agregar comentario'}
            </button>
        </div>
    );
};

export default CommentInput;