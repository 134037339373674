import './ArticleDetail.css';
import React, { useEffect, useState } from "react";
import { AuthorProfile } from "../components/AuthorProfile";
import { Tags } from "../components/Tags";
import Article from "../dto/Article";
import { useParams, Link } from "react-router-dom";
import SocialMediaShare from "../components/SocialMediaShare";
import ArticleVideo from "../components/ArticleVideo";
import Loading from "../components/Loading";
import Lottie from 'lottie-react';
import Comments from "./Comments";

const ArticleDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [articles, setArticles] = useState<Article[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const apikeySupabase = process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_ANON_KEY;
        const fetchArticles = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_URL}/rest/v1/article_details?id=eq.${id}`, {
                    method: 'GET',
                    headers: {
                        'apikey': apikeySupabase,
                        'Authorization': `Bearer ${apikeySupabase}`,
                        'Content-Type': 'application/json'
                    } as HeadersInit
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data) {
                    setArticles(data);
                } else {
                    setError('No article found');
                }
            } catch (error) {
                setError('Error fetching article');
            } finally {
                setLoading(false);
            }
        };

        fetchArticles();
    }, [id]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (articles.length === 0) {
        return (
            <div className="no-article-container">
                <Lottie animationData={require('../assets/lotties/notfound.json')} loop={true} />
                <p>No article found</p>
                <Link to="/" className="back-to-home-button">Ir al inicio</Link>
            </div>
        );
    }


    const article = articles[0];

    return (
        <div className="article-detail-container">
            <div className="article-detail-main">
                <div className="article-detail-content">
                    <h1>{article.title}</h1>
                    <p><strong>Author:</strong> {article.author.name}</p>
                    <p><strong>Published:</strong> {new Date(article.publishedAt).toLocaleDateString()}</p>
                    <img src={article.urlToImage} alt={article.title} className="article-image"/>
                    <div className="article-content">
                        <div>{article.description}</div>
                    </div>
                    <ArticleVideo article={article}/>
                    <div className="article-stats">
                        <p><strong>Views:</strong> {article.viewCount}</p>
                    </div>
                    <AuthorProfile name={article.author.name}
                                   avatarUrl={article.author.image}
                                   bio={article.author.bio}/>
                    <Comments articleId={article.id} />
                </div>
            </div>
            <div className="article-detail-sidebar">
                <div className="article-middle-rightside">
                    <SocialMediaShare text={article.title}/>
                    <Tags tags={article.tags}/>
                </div>
            </div>
        </div>
    );
};

export default ArticleDetail;
