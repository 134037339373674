// src/components/SocialMediaShare.tsx
import React from 'react';
import {
    FaFacebook,
    FaTwitter,
    FaLinkedin,
    FaInstagram,
    FaWhatsapp,
    FaTelegramPlane,
    FaRedditAlien,
    FaEnvelope
} from 'react-icons/fa';
import './SocialMediaShare.css';

interface SocialMediaShareProps {
    text: string;
}

const SocialMediaShare: React.FC<SocialMediaShareProps> = ({ text }) => {
    const urlToShare = window.location.href;
    const textToShare = encodeURIComponent(text);

    return (
        <div className="social-media-share">
            <h3>Compartelo en:</h3>
            <div className="social-media-grid">
                <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}&quote=${textToShare}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Share on Facebook"
                >
                    <FaFacebook className="social-icon"/>
                    <span>Facebook</span>
                </a>
                <a
                    href={`https://twitter.com/intent/tweet?text=${textToShare}%20${encodeURIComponent(urlToShare)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Share on Twitter"
                >
                    <FaTwitter className="social-icon"/>
                    <span>Twitter</span>
                </a>
                <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(urlToShare)}&title=${textToShare}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Share on LinkedIn"
                >
                    <FaLinkedin className="social-icon"/>
                    <span>LinkedIn</span>
                </a>
                <a
                    href={`https://www.instagram.com`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                >
                    <FaInstagram className="social-icon"/>
                    <span>Instagram</span>
                </a>
                <a
                    href={`https://api.whatsapp.com/send?text=${textToShare}%20${encodeURIComponent(urlToShare)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Share on WhatsApp"
                >
                    <FaWhatsapp className="social-icon"/>
                    <span>WhatsApp</span>
                </a>
                <a
                    href={`https://t.me/share/url?url=${encodeURIComponent(urlToShare)}&text=${textToShare}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Share on Telegram"
                >
                    <FaTelegramPlane className="social-icon"/>
                    <span>Telegram</span>
                </a>
                <a
                    href={`https://www.reddit.com/submit?url=${encodeURIComponent(urlToShare)}&title=${textToShare}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Share on Reddit"
                >
                    <FaRedditAlien className="social-icon"/>
                    <span>Reddit</span>
                </a>
                <a
                    href={`mailto:?subject=${textToShare}&body=${encodeURIComponent(urlToShare)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Share via Email"
                >
                    <FaEnvelope className="social-icon"/>
                    <span>Email</span>
                </a>
            </div>
        </div>
    );
};

export default SocialMediaShare;
