import {AuthorProfileDto} from "../dto/AuthorProfileDto";
import './AuthorProfile.css';

export const AuthorProfile = (author: AuthorProfileDto) => (
    <div className="author-profile">
        <div className="block-components">
            <div className="author-image">
                <img src={author.avatarUrl} alt={author.name}/>
            </div>
            <div className="author-info">
                <h3>{author.name}</h3>
                <p>{author.bio}</p>
            </div>
        </div>
    </div>
);