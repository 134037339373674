import React, { useState, useEffect } from 'react';
import './ArticleMiddleList.css';
import { Link } from 'react-router-dom';
import Article from "../dto/Article";
import ArticleMediumCard from "./ArticleMiddleCard";
import Loading from "./Loading";
import SocialMediaShare from "./SocialMediaShare";

const ArticleMiddleList: React.FC = () => {
    let [articles, setArticles] = useState<Article[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const apikeySupabase = process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_ANON_KEY;
        const fetchArticles = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_URL}/rest/v1/article_details`, {
                    method: 'GET',
                    headers: {
                        'apikey': apikeySupabase,
                        'Authorization': `Bearer ${apikeySupabase}`,
                        'Content-Type': 'application/json'
                    } as HeadersInit
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data) {
                    setArticles(data);
                } else {
                    setError('No articles found');
                }
            } catch (error) {
                setError('Error fetching articles');
            } finally {
                setLoading(false);
            }
        };

        fetchArticles();
    }, []);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (articles.length < 4) {
        return <p>Not enough articles to display</p>;
    }
    articles = articles.slice(2, 15);
    return (
        <div className="article-middle-block">
            <div className="article-middle-leftside">
                <div className="main-middle-article">
                    <Link to={`/article/${articles[0].id}`} className="main-middle-article-link">
                        <img src={articles[0].urlToImage} alt={articles[0].title}
                             className="main-middle-article-image"/>
                        <div className="main-middle-article-content">
                            <span className="main-middle-article-category">{articles[0].category}</span>
                            <h3 className="main-middle-article-title">{articles[0].title}</h3>
                            <div className="main-middle-article-footer">
                                <span className="main-middle-article-author">{articles[0].author.name}</span>
                                <span
                                    className="main-middle-article-date">{new Date(articles[0].publishedAt).toLocaleDateString()}</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="side-middle-articles">
                    {articles.slice(1, 10).map((article, index) => (
                        <ArticleMediumCard key={index} article={article}/>
                    ))}
                </div>
            </div>
            <div className="article-middle-rightside">
                <SocialMediaShare text="Probando sonido"/>
            </div>
        </div>
    );
};

export default ArticleMiddleList;
