import React from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import ArticleDetail from './pages/ArticleDetail';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import ArticleMiddleList from "./components/ArticleMiddleList";
import ArticleList from "./components/principal-article/ArticleList";
import AdsLarge from "./components/AdsLarge";

function App() {
    return (
        <Router>
            <div className="App">
                <Header/>
                <div className="container">
                    <Routes>
                        <Route path="/">
                            <Route
                                index
                                element={
                                    <>
                                        <ArticleList/>
                                        <AdsLarge/>
                                        <ArticleMiddleList/>
                                    </>
                                }
                            />
                        </Route>
                        <Route path="/article/:id"><Route index element={<ArticleDetail/>}/></Route>
                        <Route path="*" element={<Navigate to="/" />} /> {}

                    </Routes>
                </div>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;
