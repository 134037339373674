import React, {useState, useEffect} from 'react';
import CommentList from './CommentList';
import CommentInput from './CommentInput';
import './Comments.css';
import {Comment} from "../dto/Comment";
import Loading from "../components/Loading";

interface CommentsProps {
    articleId: string;
}

const Comments: React.FC<CommentsProps> = ({articleId}) => {
    const [comments, setComments] = useState<Comment[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const apikeySupabase = process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_ANON_KEY;
        const fetchComments = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_URL}/rest/v1/comments?article_id=eq.${articleId}&select=*`, {
                    method: 'GET',
                    headers: {
                        'apikey': apikeySupabase,
                        'Authorization': `Bearer ${apikeySupabase}`,
                        'Content-Type': 'application/json'
                    } as HeadersInit
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data) {
                    setComments(data);
                } else {
                    setError('No comments found');
                }
            } catch (error) {
                setError('Error fetching comments');
            } finally {
                setLoading(false);
            }
        };

        fetchComments();
    }, [articleId]);

    const addComment = async (text: string, author: string) => {
        const newComment: Comment = {
            article_id: articleId,
            create_by: author,
            text,
            created_at: new Date(),
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_URL}/rest/v1/comments`, {
                method: 'POST',
                body: JSON.stringify(newComment),
                headers: {
                    'apikey': process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_ANON_KEY,
                    'Authorization': `Bearer ${process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_ANON_KEY}`,
                    'Content-Type': 'application/json'
                } as HeadersInit
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setComments((prevComments) => [...prevComments, newComment]);
        } catch (error) {
            setError('Error adding comment');
        }
    };

    if (loading) {
        return <Loading/>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="comments-container">
            <h3 className="title">Comentarios</h3>
            <CommentList comments={comments}/>
            <CommentInput onAddComment={addComment}/>
        </div>
    );
};

export default Comments;
