import {TagsProps} from "../dto/TagsProps";

export const Tags: React.FC<TagsProps> = ({tags}) => (
    <div className="tags">
        <h2>Tags</h2>
        {tags.map((tag) => (
            <div key={tag} className="tag">{tag}</div>
        ))}
    </div>
);
