import React from 'react';
import DOMPurify from 'dompurify';
import './CommentList.css';
import { Comment } from "../dto/Comment";

interface CommentListProps {
    comments: Comment[];
}

const CommentList: React.FC<CommentListProps> = ({ comments }) => {
    return (
        <ul className="comment-list">
            {comments.map((comment, index) => (
                <React.Fragment key={comment.id}>
                    <li className="comment-item">
                        <div className="comment-header">
                            <span className="comment-name">{comment.create_by}</span>
                            <span className="comment-date">{comment.created_at.toString()}</span>
                        </div>
                        <p
                            className="comment-text"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.text) }}
                        />
                    </li>
                    {index < comments.length - 1 && <hr className="divider" />} {}
                </React.Fragment>
            ))}
        </ul>
    );
};

export default CommentList;
