import React, { useState } from "react";
import "./Header.css";
import image from "../images/qs.png";

const Header: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        console.log("Menu open status:", !isOpen); // Verifica si el estado cambia
    };

    return (
        <header className="header">
            <div className="logo-container">
                <img src={image} alt="Quintana System Academy Logo" className="logo-image" />
                <h1>Quintana System Academy</h1>
            </div>
            <div className="menu-toggle" onClick={toggleMenu}>
                &#9776; {/* Icono de hamburguesa */}
            </div>
            <nav className="main-nav">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/about">About</a></li>
                    <li><a href="/contact">Contact</a></li>
                </ul>
            </nav>
            <div className={`menu-drawer ${isOpen ? "open" : ""}`}>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/about">About</a></li>
                    <li><a href="/contact">Contact</a></li>
                </ul>
            </div>
        </header>
    );
};

export default Header;
