// Suponiendo que estás usando React con TypeScript o JavaScript

import React from 'react';

interface ArticleProps {
    article: {
        videoId?: string;
    };
}

const ArticleVideo: React.FC<ArticleProps> = ({article}) => {
    return (
        <div>
            {article.videoId ? (
                <div className="article-video">
                    <iframe
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${article.videoId}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default ArticleVideo;
